<template>
  <v-card flat class="ma-3">
    <v-app-bar absolute extended flat color="white" scroll-target="#scrollBody">
      <v-tabs
        v-model="tab"
        color="primary"
        icons-and-text
        height="80"
        class="mt-5"
      >
        <v-tabs-slider color="amber"></v-tabs-slider>
        <v-tab
          v-for="(quoteTab, index) in quoteTabs"
          :key="index"
          class="mx-2"
          :disabled="!checkInputValid(index)"
          :data-cy="`rfq-tab-${index}`"
        >
          <small class="mt-2">{{ quoteTab.subtitle }}</small>
          <span class="mt-1">{{ quoteTab.title }}</span>
          <v-icon color="secondary"
            >mdi-{{
              checkIconValid(index)
                ? "check-circle"
                : "checkbox-blank-circle-outline"
            }}</v-icon
          >
        </v-tab>
      </v-tabs>
      <v-sheet
        v-if="!isQuoteIncomplete && !isAdmin"
        dark
        color="warning"
        class="pa-2 mt-5"
      >
        <div class="text-right text-no-wrap">
          The quote is Read Only while FixturFab processes it.
        </div>
        <div class="text-right text-no-wrap">
          Contact
          <a class="grey--text no-underline" href="mailto:sales@fixturfab.com"
            >sales@fixturfab.com</a
          >
          if you would like to update it.
        </div>
      </v-sheet>
    </v-app-bar>
    <v-sheet id="scrollBody" class="scroller">
      <v-container fluid>
        <v-tabs-items v-model="tab" class="no-transition mt-16 pt-5">
          <v-tab-item transition="false" data-cy="rfq-tab-item-0">
            <users-view-page
              ref="user_company"
              :isAdmin="isAdmin"
              :editMode="editMode"
              :quote="quote"
              :quoteUser="quoteUser"
              :quoteCompany="quoteCompany"
              :inputValid="!!checkInputValid(1)"
              :isQuoteIncomplete="isQuoteIncomplete"
              :billingAddress="quoteBillingAddress"
              :shippingAddress="quoteShippingAddress"
              @nextTab="onNextTab"
            />
          </v-tab-item>
          <v-tab-item transition="false">
            <quote-setup-page
              ref="setup"
              :isAdmin="isAdmin"
              :editMode="editMode"
              :quote="quote"
              :quoteUser="quoteUser"
              :projectType="projectType"
              :fieldDescriptions="fieldDescriptions"
              :dutDetails="dutDetails"
              :inputValid="!!checkInputValid(2)"
              :previousValid="!!checkInputValid(1)"
              :isQuoteIncomplete="isQuoteIncomplete"
              @nextTab="onNextTab"
              @previousTab="onPreviousTab"
              @onUpdateUIState="updateUIState"
              @updateTimeline="updateTimeline"
              @updatePanelize="updatePanelize"
              @updateDutName="updateDutName"
              @onSaveDutData="saveDutData"
              @scrollToTop="onScrollToTop"
            />
          </v-tab-item>
          <v-tab-item transition="false">
            <fixture-details-page
              ref="fixture"
              :isAdmin="isAdmin"
              :editMode="editMode"
              :quote="quote"
              :quoteUser="quoteUser"
              :projectTimeline="projectTimeline"
              :projectType="projectType"
              :fixtureWiring="fixtureWiring"
              :fieldDescriptions="fieldDescriptions"
              :dutDetails="dutDetails"
              :inputValid="!!checkInputValid(3)"
              :previousValid="!!checkInputValid(1) && !!checkInputValid(2)"
              :isQuoteIncomplete="isQuoteIncomplete"
              @nextTab="onNextTab"
              @previousTab="onPreviousTab"
              @onUpdateUIState="updateUIState"
              @onSaveQuoteData="saveQuoteData"
              @onSaveFixtureDetails="saveFixtureDetails"
              @scrollToTop="onScrollToTop"
            />
          </v-tab-item>
          <v-tab-item transition="false">
            <dut-details-page
              ref="dut0"
              :calledFromQuote="true"
              :editMode="editMode"
              :tab="tab"
              :dutIndex="0"
              :quoteTabs="quoteTabs"
              :quote="quote"
              :dutDetails="dutDetails[0]"
              :dutFieldDescriptions="dutFieldDescriptions"
              :inputValid="!!checkInputValid(4)"
              :previousValid="
                !!checkInputValid(1) &&
                  !!checkInputValid(2) &&
                  !!checkInputValid(3)
              "
              :canSubmit="EDITABLE_STATUS"
              :isAdmin="isAdmin"
              :project="project"
              :currentGerbers="gerberFiles[0]"
              :dutConfig="dutConfigs[0]"
              :dutId="sortedDut.length > 0 ? sortedDut[0] : null"
              :parsedJsonBlob="parsedJsonBlob"
              :parsedTestPoints="parsedTestPoints"
              :parsedGuidePins="parsedGuidePins"
              :parsedPressurePins="parsedPressurePins"
              :parsedHoles="parsedHoles"
              :parsedDutData="parsedDutData"
              :parsedMissingHeaders="parsedMissingHeaders"
              :testpointLayers="testpointLayers"
              :testpointTypes="testpointTypes"
              :pcbStackup="pcbStackup[0] ? pcbStackup[0] : blankSvg"
              :dutUnits="dutUnits"
              :isQuoteIncomplete="isQuoteIncomplete"
              @nextTab="onNextTab"
              @previousTab="onPreviousTab"
              @updateDutName="updateDutQuote"
              @onSaveAllDutData="saveAllDutData"
              @onSaveDutData="saveDutData"
              @onSaveDutFileData="saveDutFileData"
              @onSaveGerberFiles="saveGerberFiles"
              @onUpdateQuoteDutDetails="updateQuoteDutDetails"
              @onBackToQuoteList="backToQuoteList"
              @scrollToTop="onScrollToTop"
              @deleteCurrentGerbers="deleteAllGerbers($event, gerberFiles[0])"
              @deleteCurrentDut="deleteAllDut"
              @downloadGerber="downloadGerber"
              @parseTestPoint="parseTestPoint"
              @onSaveAllDutFromModal="saveAllDutFromModal"
            />
          </v-tab-item>
          <v-tab-item transition="false">
            <dut-details-page
              ref="dut1"
              :calledFromQuote="true"
              :editMode="editMode"
              :tab="tab"
              :dutIndex="1"
              :quoteTabs="quoteTabs"
              :quote="quote"
              :dutDetails="dutDetails[1]"
              :dutFieldDescriptions="dutFieldDescriptions"
              :inputValid="!!checkInputValid(5)"
              :previousValid="!!checkInputValid(4)"
              :canSubmit="EDITABLE_STATUS"
              :isAdmin="isAdmin"
              :project="project"
              :currentGerbers="gerberFiles[1]"
              :dutConfig="dutConfigs[1]"
              :dutId="sortedDut.length > 1 ? sortedDut[1] : null"
              :parsedJsonBlob="parsedJsonBlob"
              :parsedTestPoints="parsedTestPoints"
              :parsedGuidePins="parsedGuidePins"
              :parsedPressurePins="parsedPressurePins"
              :parsedHoles="parsedHoles"
              :parsedDutData="parsedDutData"
              :parsedMissingHeaders="parsedMissingHeaders"
              :testpointLayers="testpointLayers"
              :testpointTypes="testpointTypes"
              :pcbStackup="pcbStackup[1] ? pcbStackup[1] : blankSvg"
              :dutUnits="dutUnits"
              :isQuoteIncomplete="isQuoteIncomplete"
              @previousTab="onPreviousTab"
              @updateDutName="updateDutQuote"
              @onSaveAllDutData="saveAllDutData"
              @onSaveDutData="saveDutData"
              @onSaveDutFileData="saveDutFileData"
              @onSaveGerberFiles="saveGerberFiles"
              @onUpdateQuoteDutDetails="updateQuoteDutDetails"
              @onBackToQuoteList="backToQuoteList"
              @scrollToTop="onScrollToTop"
              @deleteCurrentGerbers="deleteAllGerbers($event, gerberFiles[1])"
              @deleteCurrentDut="deleteAllDut"
              @downloadGerber="downloadGerber"
              @parseTestPoint="parseTestPoint"
              @onSaveAllDutFromModal="saveAllDutFromModal"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-container>
    </v-sheet>
  </v-card>
</template>
<script>
import pcbStackup from "pcb-stackup";
import {
  fileDownloader,
  getFileString,
  gerbersToZip,
} from "@/helpers/basehelper.js";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "QuoteTemplate",
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
    activeTab: {
      type: Number,
      default: null,
    },
  },
  components: {
    UsersViewPage: () => import("./UsersViewPage.vue"),
    QuoteSetupPage: () => import("./QuoteSetupPage.vue"),
    FixtureDetailsPage: () => import("./FixtureDetailsPage.vue"),
    DutDetailsPage: () => import("./DutDetailsPage.vue"),
  },
  data() {
    return {
      tab: 0,
      ACTIVE_TAB_AFTER_SUBMIT: {
        id: 2,
        name: "quoting",
      },
      EDITABLE_STATUS: "incomplete",
      DEFAULT_FIXTURE_CONFIG: {
        pk: 1,
        description: "DEV260 Development Fixture",
      },
      DEFAULT_CONNECTION_TYPE: {
        pk: 4,
        description: "Wire-Wrap",
      },
      DEFAULT_TIP_STYPE: {
        pk: 3,
        description: "Spear",
      },
      DEFAULT_FD_VERSION: 1,
      DEFAULT_PCB_THICKNESS: 1.6,
      config2d: {
        stackupScale: 1000,
        inchToMm: 25.4,
        isUnitMm: null,
        pressureTip: null,
        pcbHeight: null,
        pcbWidth: null,
        topSvgWidth: null,
        topLayerExist: null,
        bottomLayerExist: null,
        viewBox: [0, 0, 0, 0],
      },
    };
  },
  computed: {
    ...mapGetters({
      quoteUser: "projects/projectUser",
      quoteCompany: "projects/company",
      project: "projects/selProject",
      projectTimeline: "quotes/projectTimeline",
      projectType: "quotes/projectType",
      fixtureWiring: "quotes/fixtureWiring",
      fieldDescriptions: "quotes/fieldDescriptions",
      dutFieldDescriptions: "quotes/dutFieldDescriptions",
      quote: "quotes/quote",
      quoteBillingAddress: "quotes/billingAddress",
      quoteShippingAddress: "quotes/shippingAddress",
      dutDetails: "quotes/dutDetails",
      gerberFiles: "quotes/gerberFiles",
      sortedDut: "quotes/sortedDut",
      parsedJsonBlob: "quotes/parsedJsonBlob",
      parsedTestPoints: "quotes/parsedTestPoints",
      parsedGuidePins: "quotes/parsedGuidePins",
      parsedPressurePins: "quotes/parsedPressurePins",
      parsedHoles: "quotes/parsedHoles",
      parsedDutData: "quotes/parsedDutData",
      parsedMissingHeaders: "quotes/parsedMissingHeaders",
      pcbStackup: "quotes/pcbStackup",
      blankSvg: "quotes/blankSvg",
      dutConfigs: "quotes/dutConfigs",
      dutUnits: "quotes/dutUnits",
      testpointLayers: "dutconfigs/testpointLayers",
      testpointTypes: "dutconfigs/testpointTypes",
      isAdmin: "auth/isAdmin",
      userCompany: "ui/userCompany",
      fixtureDetails: "ui/fixtureDetails",
      dutUiDetails: "ui/dutUiDetails",
      quoteTabs: "ui/quoteTabs",
      billingAddress: "ui/billingAddress",
      shippingAddress: "ui/shippingAddress",
      shippingSameAsBilling: "ui/shippingSameAsBilling",
    }),
    isQuoteIncomplete() {
      return (
        Object.keys(this.quote).length == 0 ||
        this.quote.status == this.EDITABLE_STATUS
      );
    },
  },
  methods: {
    fileDownloader,
    getFileString,
    gerbersToZip,
    ...mapActions({
      saveQuote: "quotes/saveQuote",
      saveDutDetails: "quotes/saveDutDetail",
      getDutDetails: "quotes/getDutDetails",
      removeDutDetail: "quotes/deleteDutDetail",
      getDutConfig: "quotes/getDutConfigsByQuote",
      convertTestPoint: "quotes/parseTestPointFile",
      updateDutConfigs: "quotes/updateDutConfigs",
      getQuoteOnly: "quotes/getQuoteOnly",
      postGerberLayerFile: "quotes/postGerberLayerFile",
      removeGerberLayerFile: "quotes/removeGerberLayerFile",
      patchBulkGerbers: "dutconfigs/patchBulkGerbers",
      saveTestPointsBlob: "dutconfigs/saveTestPointsBlob",
      postProject: "projects/saveProject",
      removeProject: "projects/deleteProject",
      postFixtureDesign: "fixturedesign/saveFixtureDesign",
      saveDesignFilePackage: "fixturedesign/postDesignFilePackage",
      createDutConfig: "dutconfigs/saveDutConfigs",
      toggleLoading: "ui/loading",
    }),
    async deleteAllGerbers(dutIndex, payload) {
      console.log(payload, dutIndex);
      try {
        this.toggleLoading("Removing Gerbers and all relevant data");
        await this.deleteCurrentGerbers(dutIndex);
        let configData = new FormData();
        configData.append("pcb_stackup", "");
        configData.append("zipped_gerber_files", new File([], ""));
        const configPayload = {
          pk: this.sortedDut[dutIndex],
          data: configData,
        };
        let detailsData = new FormData();
        detailsData.append("gerber_zip", new File([], ""));
        const detailsPayload = {
          editMode: true,
          id: this.dutDetails[dutIndex].id,
          data: detailsData,
        };
        console.log(configPayload, detailsPayload);
        await this.removeGerberLayerFile({
          dutDetailsId: this.dutDetails[dutIndex].id,
          index: dutIndex,
        });
        await this.updateDutConfigs(configPayload);
        await this.saveDutDetails(detailsPayload);
        this.toggleLoading();
        await Promise.all([
          this.getDutDetails({ params: { quote_id: this.quote.id } }),
        ]);
      } catch (err) {
        this.toggleLoading();
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    async deleteCurrentGerbers(dutIndex) {
      try {
        let gerberPayload = new FormData();
        gerberPayload.append("user", this.quote.user);
        gerberPayload.append(
          "fixture_design",
          Object.keys(this.quote.fixture_design_details)[0]
        );

        await this.patchBulkGerbers({
          project_id: Object.values(this.quote.fixture_design_details)[0]
            ?.project_pk,
          dut_id: this.sortedDut[dutIndex],
          data: gerberPayload,
        });
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    async deleteAllDut(dutIndex) {
      try {
        this.toggleLoading("Removing DUT file");
        let detailsData = new FormData();
        detailsData.append("test_point_file", new File([], ""));
        detailsData.append("units", 1);
        detailsData.append("x_origin", 0);
        detailsData.append("y_origin", 0);
        detailsData.append("pcb_width", "");
        detailsData.append("pcb_height", "");
        detailsData.append("pcb_thickness", 1.6);
        detailsData.append("dut_file_data", "{}");
        const detailsPayload = {
          editMode: true,
          id: this.dutDetails[dutIndex].id,
          data: detailsData,
        };
        await this.saveDutDetails(detailsPayload);
        await this.getDutDetails({ params: { quote_id: this.quote.id } }),
          this.$refs[`dut${dutIndex}`] &&
            this.$refs[`dut${dutIndex}`].initDutFields();
        this.toggleLoading();
      } catch (err) {
        this.toggleLoading();
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    isUserDetailsValid() {
      return (
        this.quoteUser?.full_name &&
        this.userCompany?.full_name &&
        this.userCompany?.full_name == this.quoteUser.full_name &&
        ((this.userCompany.company_request.length > 0 &&
          this.userCompany.company_request == this.quoteUser.company_request) ||
          this.quoteCompany?.name) &&
        (this.editMode || Object.keys(this.quote).length > 0
          ? this.quoteBillingAddress &&
            this.quoteBillingAddress.name == this.billingAddress.name
          : this.billingAddress.name) &&
        (this.editMode || Object.keys(this.quote).length > 0
          ? this.quoteBillingAddress.company_name ==
            this.billingAddress.company_name
          : this.billingAddress.company_name) &&
        (this.editMode || Object.keys(this.quote).length > 0
          ? this.quoteBillingAddress.email == this.billingAddress.email
          : this.billingAddress.email) &&
        (this.editMode || Object.keys(this.quote).length > 0
          ? this.quoteBillingAddress.phone == this.billingAddress.phone
          : this.billingAddress.phone) &&
        (this.editMode || Object.keys(this.quote).length > 0
          ? this.quoteBillingAddress.street1 == this.billingAddress.street1
          : this.billingAddress.street1) &&
        (this.editMode || Object.keys(this.quote).length > 0
          ? this.quoteBillingAddress.street2 == this.billingAddress.street2
          : true) &&
        (this.editMode || Object.keys(this.quote).length > 0
          ? this.quoteBillingAddress.city == this.billingAddress.city
          : this.billingAddress.city) &&
        (this.editMode || Object.keys(this.quote).length > 0
          ? this.quoteBillingAddress.state == this.billingAddress.state
          : this.billingAddress.state) &&
        (this.editMode || Object.keys(this.quote).length > 0
          ? this.quoteBillingAddress.country == this.billingAddress.country
          : this.billingAddress.country) &&
        (this.editMode || Object.keys(this.quote).length > 0
          ? this.quoteBillingAddress.zip == this.billingAddress.zip
          : this.billingAddress.zip) &&
        (this.editMode || Object.keys(this.quote).length > 0
          ? this.quote.shipping_same_as_billing == this.shippingSameAsBilling
          : true) &&
        (!this.shippingSameAsBilling
          ? this.editMode || Object.keys(this.quote).length > 0
            ? this.quoteShippingAddress &&
              this.quoteShippingAddress.name == this.shippingAddress.name
            : this.shippingAddress.name
          : true) &&
        (!this.shippingSameAsBilling
          ? this.editMode || Object.keys(this.quote).length > 0
            ? this.quoteShippingAddress &&
              this.quoteShippingAddress.company_name ==
                this.shippingAddress.company_name
            : this.shippingAddress.company_name
          : true) &&
        (!this.shippingSameAsBilling
          ? this.editMode || Object.keys(this.quote).length > 0
            ? this.quoteShippingAddress &&
              this.quoteShippingAddress.email == this.shippingAddress.email
            : this.shippingAddress.email
          : true) &&
        (!this.shippingSameAsBilling
          ? this.editMode || Object.keys(this.quote).length > 0
            ? this.quoteShippingAddress &&
              this.quoteShippingAddress.phone == this.shippingAddress.phone
            : this.shippingAddress.phone
          : true) &&
        (!this.shippingSameAsBilling
          ? this.editMode || Object.keys(this.quote).length > 0
            ? this.quoteShippingAddress &&
              this.quoteShippingAddress.street1 == this.shippingAddress.street1
            : this.shippingAddress.street1
          : true) &&
        (!this.shippingSameAsBilling
          ? this.editMode || Object.keys(this.quote).length > 0
            ? this.quoteShippingAddress &&
              this.quoteShippingAddress.street2 == this.shippingAddress.street2
            : true
          : true) &&
        (!this.shippingSameAsBilling
          ? this.editMode || Object.keys(this.quote).length > 0
            ? this.quoteShippingAddress &&
              this.quoteShippingAddress.city == this.shippingAddress.city
            : this.shippingAddress.city
          : true) &&
        (!this.shippingSameAsBilling
          ? this.editMode || Object.keys(this.quote).length > 0
            ? this.quoteShippingAddress &&
              this.quoteShippingAddress.state == this.shippingAddress.state
            : this.shippingAddress.state
          : true) &&
        (!this.shippingSameAsBilling
          ? this.editMode || Object.keys(this.quote).length > 0
            ? this.quoteShippingAddress &&
              this.quoteShippingAddress.country == this.shippingAddress.country
            : this.shippingAddress.country
          : true) &&
        (!this.shippingSameAsBilling
          ? this.editMode || Object.keys(this.quote).length > 0
            ? this.quoteShippingAddress &&
              this.quoteShippingAddress.zip == this.shippingAddress.zip
            : this.shippingAddress.zip
          : true)
      );
    },
    isQuoteSetupValid() {
      return (
        this.fixtureDetails.project_name &&
        this.fixtureDetails.project_name == this.quote.project_name &&
        this.fixtureDetails.number_of_unique_duts &&
        this.fixtureDetails.number_of_unique_duts ==
          this.quote.number_of_unique_duts &&
        this.fixtureDetails.dut_name_1 &&
        this.fixtureDetails.dut_name_1 == this.dutDetails[0].dut_name &&
        this.fixtureDetails.project_type &&
        this.fixtureDetails.project_type == this.quote.project_type &&
        (this.quote.number_of_unique_duts > 1
          ? this.fixtureDetails.dut_name_2 &&
            this.fixtureDetails.dut_name_2 == this.dutDetails[1].dut_name
          : true)
      );
    },
    isFixtureDetailsValid() {
      return (
        this.fixtureDetails.project_timeline &&
        this.fixtureDetails.project_timeline == this.quote.project_timeline &&
        this.fixtureDetails.project_timeline_details ==
          this.quote.project_timeline_details &&
        this.fixtureDetails.estimated_volumes &&
        this.fixtureDetails.estimated_volumes == this.quote.estimated_volumes &&
        this.fixtureDetails.number_of_fixtures &&
        this.fixtureDetails.number_of_fixtures ==
          this.quote.number_of_fixtures &&
        this.fixtureDetails.fixture_wiring &&
        this.fixtureDetails.fixture_wiring == this.quote.fixture_wiring &&
        this.fixtureDetails.test_instrumentation_selection_and_integration ==
          this.quote.test_instrumentation_selection_and_integration &&
        this.fixtureDetails.test_software_development ==
          this.quote.test_software_development &&
        this.fixtureDetails.custom_back_plate == this.quote.custom_back_plate &&
        this.fixtureDetails.additional_services_details ==
          this.quote.additional_services_details &&
        this.fixtureDetails.panelized_fixture == this.quote.panelized_fixture &&
        this.fixtureDetails.duts_in_panel == this.quote.duts_in_panel &&
        this.fixtureDetails.duts_per_row == this.quote.duts_per_row &&
        this.fixtureDetails.duts_per_column == this.quote.duts_per_column &&
        this.fixtureDetails.panel_details == this.quote.panel_details &&
        this.fixtureDetails.test_instrumentation_selection_and_integration ==
          this.quote.test_instrumentation_selection_and_integration &&
        this.fixtureDetails.test_software_development ==
          this.quote.test_software_development &&
        this.fixtureDetails.custom_back_plate == this.quote.custom_back_plate &&
        this.fixtureDetails.additional_services_details ==
          this.quote.additional_services_details
      );
    },
    isDutDetails1Valid() {
      return (
        this.dutUiDetails.dut_name_0 &&
        this.dutUiDetails.dut_name_0 == this.dutDetails[0].dut_name &&
        this.dutUiDetails.pcb_width_0 &&
        this.dutUiDetails.pcb_width_0 == this.dutDetails[0].pcb_width &&
        this.dutUiDetails.pcb_height_0 &&
        this.dutUiDetails.pcb_height_0 == this.dutDetails[0].pcb_height &&
        this.dutUiDetails.pcb_thickness_0 &&
        this.dutUiDetails.pcb_thickness_0 == this.dutDetails[0].pcb_thickness &&
        this.dutDetails[0].test_point_file &&
        this.dutDetails[0].step_file &&
        (this.quote.test_instrumentation_selection_and_integration ||
        this.quote.test_software_development
          ? this.dutDetails[0].schematic_file
          : true) &&
        ((this.gerberFiles[0]?.length > 0 &&
          !this.dutUiDetails.zipped_gerbers_0) ||
          this.dutDetails[0].odb_zip ||
          this.dutDetails[0].ipc_zip) &&
        this.dutUiDetails.flex_board_0 == this.dutDetails[0].flex_board &&
        this.dutUiDetails.dual_sided_probing_0 ==
          this.dutDetails[0].dual_sided_probing &&
        !this.dutUiDetails.test_point_file_0 &&
        !this.dutUiDetails.step_file_0 &&
        !this.dutUiDetails.gerber_zip_0 &&
        !this.dutUiDetails.odb_zip_0 &&
        !this.dutUiDetails.ipc_zip_0 &&
        !this.dutUiDetails.schematic_file_0 &&
        !this.dutUiDetails.other_file_0 &&
        this.dutUiDetails.additional_comments_0 ==
          this.dutDetails[0].additional_comments
      );
    },
    isDutDetails2Valid() {
      return (
        this.dutUiDetails.dut_name_1 &&
        this.dutUiDetails.dut_name_1 == this.dutDetails[1].dut_name &&
        this.dutUiDetails.pcb_width_1 &&
        this.dutUiDetails.pcb_width_1 == this.dutDetails[1].pcb_width &&
        this.dutUiDetails.pcb_height_1 &&
        this.dutUiDetails.pcb_height_1 == this.dutDetails[1].pcb_height &&
        this.dutUiDetails.pcb_thickness_1 &&
        this.dutUiDetails.pcb_thickness_1 == this.dutDetails[1].pcb_thickness &&
        this.dutDetails[1].test_point_file &&
        this.dutDetails[1].step_file &&
        (this.quote.test_instrumentation_selection_and_integration ||
        this.quote.test_software_development
          ? this.dutDetails[1].schematic_file
          : true) &&
        ((this.gerberFiles[1]?.length > 0 &&
          !this.dutUiDetails.zipped_gerbers_1) ||
          this.dutDetails[1].odb_zip ||
          this.dutDetails[1].ipc_zip) &&
        this.dutUiDetails.flex_board_1 == this.dutDetails[1].flex_board &&
        this.dutUiDetails.dual_sided_probing_1 ==
          this.dutDetails[1].dual_sided_probing &&
        !this.dutUiDetails.test_point_file_1 &&
        !this.dutUiDetails.step_file_1 &&
        !this.dutUiDetails.gerber_zip_1 &&
        !this.dutUiDetails.odb_zip_1 &&
        !this.dutUiDetails.ipc_zip_1 &&
        !this.dutUiDetails.schematic_file_1 &&
        !this.dutUiDetails.other_file_1 &&
        this.dutUiDetails.additional_comments_1 ==
          this.dutDetails[1].additional_comments
      );
    },
    checkInputValid(index) {
      if (index == 0) {
        return true;
      } else if (index == 1) {
        return this.isUserDetailsValid();
      } else if (index == 2) {
        return this.isUserDetailsValid() && this.isQuoteSetupValid();
      } else if (index == 3) {
        return (
          this.isUserDetailsValid() &&
          this.isQuoteSetupValid() &&
          this.isFixtureDetailsValid()
        );
      } else if (index == 4) {
        return (
          this.isUserDetailsValid() &&
          this.isQuoteSetupValid() &&
          this.isFixtureDetailsValid() &&
          this.isDutDetails1Valid()
        );
      } else if (index == 5) {
        return (
          this.isUserDetailsValid() &&
          this.isQuoteSetupValid() &&
          this.isFixtureDetailsValid() &&
          this.isDutDetails1Valid() &&
          this.isDutDetails2Valid()
        );
      } else {
        return false;
      }
    },
    checkIconValid(index) {
      if (index == 0) {
        return this.isUserDetailsValid();
      } else if (index == 1) {
        return this.isQuoteSetupValid();
      } else if (index == 2) {
        return this.isFixtureDetailsValid();
      } else if (index == 3) {
        return this.isDutDetails1Valid();
      } else if (index == 4) {
        return this.isDutDetails2Valid();
      } else {
        return false;
      }
    },
    onNextTab() {
      this.tab += 1;
      this.onScrollToTop();
    },
    onPreviousTab() {
      this.tab -= 1;
    },
    onScrollToTop() {
      this.$vuetify.goTo(0, { container: "#scrollBody" });
    },
    updateTimeline(payload) {
      this.$refs.fixture && this.$refs.fixture.updateTimeline(payload);
    },
    updatePanelize(payload) {
      this.$refs.fixture && this.$refs.fixture.updatePanelize(payload);
    },
    updateDutName({ index, value }) {
      this.$refs[`dut${index}`] &&
        this.$refs[`dut${index}`].updateDutName(value);
    },
    updateDutQuote(payload) {
      this.$refs[`setup`] && this.$refs[`setup`].updateDutName(payload);
    },
    updateUIState(payload) {
      this.$store.dispatch("ui/updateQuoteFixtureDetails", payload);
    },
    updateQuoteDutDetails(payload) {
      this.$store.dispatch("ui/updateQuoteDutDetails", payload);
    },
    async saveQuoteData({ key, value }) {
      const actionPayload = {
        editMode: true,
        id: this.quote.id,
        data: {
          [key]: value,
        },
      };
      try {
        this.toggleLoading("Submitting");
        await this.saveQuote(actionPayload);
        await this.getDutDetails({ params: { quote_id: this.quote.id } });
        this.toggleLoading();
      } catch (err) {
        this.toggleLoading();
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    async saveFixtureDetails(payload) {
      try {
        this.toggleLoading("Saving Quote - Fixture Details");
        await this.saveQuote(payload);
        this.toggleLoading();
        this.onNextTab();
      } catch (err) {
        this.toggleLoading();
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    async saveAllDutData({
      payload,
      nextTab,
      submitPayload,
      dutIndex,
      blobs,
      configData,
    }) {
      const { testpoints, guidepins, pressurepins } = blobs;
      try {
        const configPayload = {
          pk: this.sortedDut[dutIndex],
          data: configData,
        };
        this.toggleLoading("Saving DUT Details");
        await this.saveDutDetails(payload);
        await Promise.all([
          this.updateDutConfigs(configPayload),
          this.saveAllTestPoints({ testpoints, dutIndex }),
          this.saveAllGuidePins({ guidepins, dutIndex }),
          this.saveAllPressurePins({ pressurepins, dutIndex }),
        ]);
        this.toggleLoading();
        if (nextTab) {
          this.onNextTab();
        } else {
          this.isQuoteIncomplete && (await this.submitQuote(submitPayload));
          this.$router.push({
            name: "quotes",
            params: { activeTab: this.ACTIVE_TAB_AFTER_SUBMIT },
          });
        }
        await this.getDutDetails({ params: { quote_id: this.quote.id } });
        this.$refs[`dut${dutIndex}`] &&
          this.$refs[`dut${dutIndex}`].initializeAllState();
      } catch (err) {
        this.toggleLoading();
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    async saveAllTestPoints({ testpoints, dutIndex }) {
      try {
        const tpData = {
          test_points: testpoints,
        };
        console.log("TP Blob Patch Payload", testpoints, tpData);
        await this.saveTestPointsBlob({
          project_id: Object.values(this.quote.fixture_design_details)[0]
            ?.project_pk,
          dut_id: this.sortedDut[dutIndex],
          data: tpData,
          data_type: "test-points",
        });
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    async saveAllGuidePins({ guidepins, dutIndex }) {
      try {
        const gpData = {
          guide_pins: guidepins,
        };
        await this.saveTestPointsBlob({
          project_id: Object.values(this.quote.fixture_design_details)[0]
            ?.project_pk,
          dut_id: this.sortedDut[dutIndex],
          data: gpData,
          data_type: "guide-pins",
        });
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    async saveAllPressurePins({ pressurepins, dutIndex }) {
      try {
        const ppData = {
          pressure_pins: pressurepins,
        };
        await this.saveTestPointsBlob({
          project_id: Object.values(this.quote.fixture_design_details)[0]
            ?.project_pk,
          dut_id: this.sortedDut[dutIndex],
          data: ppData,
          data_type: "pressure-pins",
        });
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    async saveAllDutFromModal({ payload, dutIndex }) {
      try {
        this.toggleLoading("Saving DUT Details");
        await this.saveDutDetails(payload);
        this.toggleLoading();
        await this.getDutDetails({ params: { quote_id: this.quote.id } });
        this.$refs[`dut${dutIndex}`] &&
          this.$refs[`dut${dutIndex}`].initStateAfterSave({
            varStr: "formDut1TestPoint",
            uiStr: "test_point_file",
            value: null,
          });
      } catch (err) {
        this.toggleLoading();
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    async saveDutData({ id, key, value, index }) {
      const actionPayload = {
        editMode: Object.keys(this.dutDetails[index]).length > 1,
        id,
        data: {
          quote: this.quote.id,
          [key]: value,
        },
      };
      try {
        this.toggleLoading("Saving");
        await this.saveDutDetails(actionPayload);
        await this.getDutDetails({ params: { quote_id: this.quote.id } });
        if (key == "dut_name") this.updateDutQuote({ index, value });
        this.toggleLoading();
      } catch (err) {
        this.toggleLoading();
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    async saveDutFileData({ id, key, value }) {
      let inputData = new FormData();
      inputData.append(key, value);

      const actionPayload = {
        editMode: true,
        id,
        data: inputData,
      };

      try {
        await this.saveDutDetails(actionPayload);
        await this.getDutDetails({ params: { quote_id: this.quote.id } });
      } catch (err) {
        this.toggleLoading();
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    async saveGerberFiles({
      dutIndex,
      gerbers,
      zipped_file,
      withExistingGerbers,
      isBackwardCompatibility,
    }) {
      this.toggleLoading("One moment please, saving Gerber files");

      try {
        if (withExistingGerbers) {
          await this.removeGerberLayerFile({
            dutDetailsId: this.dutDetails[dutIndex].id,
            index: dutIndex,
          });
          await this.saveDutFileData({
            id: this.dutDetails[dutIndex].id,
            key: "gerber_zip",
            value: new File([], ""),
          });
        }
        for (let i = 0; i < gerbers.gerberLayers.length; i++) {
          let gerberPayload = new FormData();
          gerberPayload.append("layer", gerbers.gerberLayers[i].layer);
          gerberPayload.append("side", gerbers.gerberLayers[i].side);
          gerberPayload.append("svg_data", gerbers.gerberLayers[i].svg);
          gerberPayload.append("dut_details", this.dutDetails[dutIndex].id);
          gerberPayload.append(
            "file",
            new Blob([gerbers.gerberLayers[i].gerber], {
              type: "application/vnd.gerber",
            }),
            gerbers.gerberLayers[i].file
          );
          await this.postGerberLayerFile(gerberPayload);
        }
      } catch (err) {
        this.toggleLoading("Rolling back saved Gerber files");
        await this.removeGerberLayerFile({
          dutDetailsId: this.dutDetails[dutIndex].id,
          index: dutIndex,
        });
        this.toggleLoading();
        this.$store.dispatch("ui/snackLink", {
          message1:
            "Something went wrong while saving Gerber layers, you may upload another zip file or contact ",
          message2: " for help.",
          email: true,
          link: {
            url: "mailto:support@fixturfab.com",
            text: "support@fixturfab.com",
          },
        });
        return;
      }

      try {
        if (!isBackwardCompatibility) {
          await this.saveDutFileData({
            id: this.dutDetails[dutIndex].id,
            key: "gerber_zip",
            value: zipped_file,
          });
        } else {
          await this.getDutDetails({ params: { quote_id: this.quote.id } });
        }

        if (Object.keys(this.quote.fixture_design_details).length == 0) {
          const fdPayload = {
            project_name: this.quote.project_name,
            user: this.quote.user,
            fixture_config: this.DEFAULT_FIXTURE_CONFIG.pk,
            connection_type: this.DEFAULT_CONNECTION_TYPE.pk,
            tip_style: this.DEFAULT_TIP_STYPE.pk,
            version: this.DEFAULT_FD_VERSION,
            quote: this.quote.id,
          };
          await this.postFD(fdPayload);
          await this.getQuoteOnly(this.quote.id);
        }
        await this.updateDutConfigs({
          pk: this.sortedDut[dutIndex],
          data: await this.gerbersToZip(gerbers.gerberFiles),
        });
        this.toggleLoading();
      } catch (err) {
        this.toggleLoading();
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    async submitQuote(payload) {
      await this.saveQuoteData(payload);
    },
    backToQuoteList() {
      this.$router.push({
        name: "quotes",
      });
    },
    async saveGerbersSvg({ dutIndex }) {
      this.toggleLoading("Saving Gerbers 2D View");
      try {
        const layers = await Promise.all(
          this.gerberFiles[dutIndex].map(async (element) => ({
            filename: element.file,
            gerber: await this.getFileString(element.file),
          }))
        );
        const response = await pcbStackup(layers);
        const actionPayload = {
          pk: this.sortedDut[dutIndex],
          data: { pcb_stackup: JSON.stringify(response) },
        };
        console.log(
          "SAVING GERBERS SVG",
          this.gerberFiles[dutIndex],
          layers,
          response,
          actionPayload
        );
        await this.updateDutConfigs(actionPayload);
        this.toggleLoading();
      } catch (err) {
        this.toggleLoading();
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    async fetchCurrentGerbers({ loadingText }) {
      this.toggleLoading(loadingText);
      this.$store.commit("quotes/INIT_GERBER_FILES");
      try {
        for (let i = 0; i < this.sortedDut.length; i++) {
          await this.getDutConfig(this.sortedDut[i]);
        }
        this.toggleLoading();
      } catch (err) {
        this.toggleLoading();
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    async parseTestPoint({ payload, dutId }) {
      console.log("Quote Template", payload);
      try {
        this.toggleLoading("Parsing");
        await this.convertTestPoint(payload);
        this.toggleLoading();
        this.$refs[`dut${dutId}`] && this.$refs[`dut${dutId}`].updateConfig2d();
      } catch (err) {
        this.toggleLoading();
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
        this.$refs[`dut${dutId}`] &&
          this.$refs[`dut${dutId}`].initTestPointFile();
      }
    },
    downloadGerber({ url, file }) {
      this.toggleLoading("Downloading, one moment please");
      this.fileDownloader({ url, file });
      this.toggleLoading();
    },
    async postFD({
      project_name,
      user,
      fixture_config,
      connection_type,
      tip_style,
      version,
      quote,
    }) {
      let projectObj;
      try {
        //Post to Project
        const projectPayload = {
          project_name,
          user,
        };
        projectObj = await this.postProject(projectPayload);
        //Post to FD
        let dutData = new FormData();
        dutData.append("project", projectObj.pk);
        dutData.append("user", projectObj.user);
        dutData.append("fixture_config", fixture_config);
        dutData.append("receptacle_connection_preference", connection_type);
        dutData.append("tip_style_preference", tip_style);
        dutData.append("version", version);
        dutData.append("quote", quote);
        const response = await this.postFixtureDesign(dutData);
        //Post to DFP
        let designData = new FormData();
        designData.append("user", response.data.user);
        designData.append("project", response.data.project);
        designData.append("fixture_design", response.data.pk);
        designData.append("version", response.data.version);
        designData.append("active", true);
        await this.saveDesignFilePackage(designData);

        //Post to DC
        for (let i = 0; i < Object.keys(this.quote.dut_details).length; i++) {
          await this.postDC({
            user,
            project: projectObj.pk,
            fixture_design: response.data.pk,
            dut_name: Object.values(this.quote.dut_details)[i],
            dut_details: Object.keys(this.quote.dut_details)[i],
          });
        }
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
        await this.removeProject(projectObj.pk);
      }
    },
    async postDC({ user, project, fixture_design, dut_name, dut_details }) {
      let dutData = new FormData();
      dutData.append("name", dut_name);
      dutData.append("pcb_thickness", this.DEFAULT_PCB_THICKNESS);
      dutData.append("user", user);
      dutData.append("project", project);
      dutData.append("fixture_design", fixture_design);
      dutData.append("dut_details", dut_details);
      await this.createDutConfig(dutData);
    },
  },
  async mounted() {
    if (this.activeTab) this.tab = this.activeTab;
  },
};
</script>
<style scoped>
.scroller {
  height: calc(100vh - 190px);
  overflow-y: auto;
}
</style>
